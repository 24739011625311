import React from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import Img from 'gatsby-image'

import theme from '../constants/theme'

// const StyledRow = styled.div`

// `

const StyledCardImage = styled(Img)`
  max-width: 100%;
  height: ${theme.sizes.cardHeight};
`

const CardRow = () => (
  <StaticQuery
    query={graphql`
      query {
        cabinImage: file(relativePath: { eq: "cabins/cabin_image.jpg" }) {
          childImageSharp {
            fluid(maxHeight: 400, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <div className="row mt-3">
        <div className="col-md-6 mb-3 pr-md-2">
          <div className="card shadow h-100 border border-dark">
            <div className="card-body">
              <strong className="d-inline-block mb-2 text-primary">
                Place to stay
              </strong>
              <h3 className="mb-0">
                <Link className="text-dark" to="/cabins">
                  Cabins
                </Link>
              </h3>
              <p className="card-text mb-auto">
                View our variety of cabins and facilities.
              </p>
            </div>
            <StyledCardImage
              fluid={data.cabinImage.childImageSharp.fluid}
              alt="Cabin at Mollers Rorbuer Lofoten"
            />
            <div className="p-2 text-center">
              <Link className="btn btn-dark" to="/cabins" role="button">
                More Information
              </Link>
            </div>
          </div>
        </div>
        <div className="col-md-6 mb-3 pl-md-2">
          <div className="card shadow h-100 border border-dark">
            <div className="card-body">
              <strong className="d-inline-block mb-2 text-primary">
                Travel
              </strong>
              <h3 className="mb-0">Location</h3>
              <p className="card-text mb-auto">How to get here.</p>
            </div>
            <iframe
              id="mapimg"
              title="google-maps"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1498.5700602311308!2d13.087047416434325!3d67.93868698544824!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xbb76be4d89f2a419!2sM%C3%B8ller&#39;s+Rorbuer!5e0!3m2!1sen!2suk!4v1535973512115"
              width="100%"
              height={theme.sizes.cardHeight}
              frameBorder="0"
              style={{ border: '0' }}
              allowFullScreen
            />
            <div className="p-2 text-center">
              <a
                className="btn btn-dark"
                href="https://goo.gl/maps/UgZpmGieuX32"
                target="_blank"
                role="button"
                rel="noopener noreferrer"
              >
                Full Map
              </a>
            </div>
          </div>
        </div>
      </div>
    )}
  />
)

export default CardRow
