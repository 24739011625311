import React from 'react'

import Layout from '../components/Layout'
import JumboHeader from '../components/JumboHeader'
import CardRow from '../components/CardRow'
import ContactCard from '../components/ContactCard'

// import theme from '../constants/theme'

const IndexPage = () => (
  <Layout>
    <JumboHeader height={200}>
      <h1 className="font-weight-bold text-dark">Cabins in Reine, Lofoten</h1>
      <p className="lead font-weight-normal text-black">
        Five cabins located by the sea, with an excellent view of Reine and the
        surrounding mountains.
      </p>
    </JumboHeader>
    <CardRow />
    <ContactCard />
  </Layout>
)

export default IndexPage
